import {
  GET_CART_DETAIL,
  MANAGE_USER_CART,
  MANAGE_USER_CHECKOUT_CART,
  MANAGE_REPEAT_ITEM_CHECKOUT_CART,
  ADD_SUGGESTION,
  RIDER_SUGGESTION,
  ITEM_DETAIL_SUCCESS,
  SET_DELIVERED_ADDRESS,
  REMOVE_ITEM_CHECKOUT_CART,
  FINAL_PAYMENT_SUCCESS,
  REORDER_SUCCESS,
  SET_MERCHANT_REFERENCE,
  _dispatch
} from '../actions/cart';

import { checkNewCustomizeRequest } from '../utilities';

const INITIAL_STATE = {cartdetail: {}, cartFinalInfo: {}};

export default function(state = INITIAL_STATE, action) {
  	switch(action.type) {
	    case GET_CART_DETAIL:
	      	//return { ...state, cartdetail: action.payload};
	      	return _dispatch({ ...state, cartdetail: action.payload}, true, 'user_cart_info');
	      	break;
	    case MANAGE_USER_CART:
	    	var {shopId, item} = action;
	    	var {cartdetail} = state;

	    	if(cartdetail.shopId == shopId){
	    		let items = [...cartdetail.items];
	    		if(item.isItemCustom == 0){
		    		const result = cartdetail.items.filter((i, index) => {
		    			if(i.id == item.id){
		    				item = {...item, uniqueId: i.uniqueId};
		    				items[index] = item;
		    				return true;
		    			}
		    			return false;
		    		});
		    		if(result.length){

		    			cartdetail.items = [...items]; 
		    		}else{
		    			//console.log('11111');
		    			item = {...item, uniqueId: Date.now()};
		    			cartdetail.items.push(item); 
		    		}
		    		//console.log('reducer', cartdetail);
		    	} else {
		    		if(item.stats == '1'){
		    			items && items.length > 0 && items.map((obj, endex) => {
						    if(obj.id == item.id){
						    	obj.qty -= 1;
						    	if(!obj.qty){
						    	  	items.splice(endex, 1);
						    	} else {
						    		items[endex] = obj;
						    	}
						    	cartdetail.items = [...items]; 
						    }
						})
		    		} else {
		    			var result = checkNewCustomizeRequest(item, items);
				    	if(result[0]){
				    		items && items.length > 0 && items.map((obj, endex) => {
							    if(endex == result[1]){
							    	obj.qty += item.qty;
							    	items[endex] = obj;
							    	cartdetail.items = [...items]; 
							    }
							})
				    	} else {
				    		//item.uniqueId = Math.random();
				    		//console.log('2222');
				    		item = {...item, uniqueId: Date.now()};
				    		cartdetail.items.push(item); 	
				    	}
		    		}
			    	//console.log('testing', result);

			    }
	    	} else {
	    		item = {...item, uniqueId: Date.now()};
	    		cartdetail = {shopId, items: [item]};
	    	}

	    	if(cartdetail && cartdetail.items && cartdetail.items.length > 0){
	    		return _dispatch({ ...state, cartdetail}, true, 'qty-menu');
	    	} else {
	    		return _dispatch({ ...state, cartFinalInfo: {}, cartdetail: {}}, true, 'qty-menu');
	    	}
	    	
	      	break;
	    case MANAGE_USER_CHECKOUT_CART:
	    	var {shopId, item} = action;
	    	var {cartdetail} = state;

	    	//console.log(shopId, '11', item, '11', cartdetail);

	    	if(cartdetail.shopId == shopId){
	    		let items = [...cartdetail.items];
	    		if(item.isItemCustom == 0){
	    			items && items.length > 0 && items.map((obj, endex) => {
					    if(obj.id == item.id){
					    	if(item.stats == '1'){
					    		obj.qty -= 1;
					    	} else {
					    		obj.qty += 1;
					    	}

					    	if(!obj.qty){
					    	  	items.splice(endex, 1);
					    	} else {
					    		items[endex] = obj;
					    	}
					    	//items[endex] = obj;
					    	cartdetail.items = [...items]; 
					    }
					})
		    	} else {
		    		var result = checkNewCustomizeRequest(item, items);
			    	if(result[0]){
			    		items && items.length > 0 && items.map((obj, endex) => {
						    if(endex == result[1]){
						    	if(item.stats == '1'){
					    			obj.qty -= 1;
						    	} else {
						    		obj.qty += 1;
						    	}

						    	if(!obj.qty){
						    	  	items.splice(endex, 1);
						    	} else {
						    		items[endex] = obj;
						    	}
						    	//items[endex] = obj;
						    	cartdetail.items = [...items]; 
						    }
						})
			    	}
			    	//console.log('testing', result);

			    }
	    	} else {
	    		cartdetail = {shopId, items: [item]};
	    	}

	    	//console.log('cartdetail9999', cartdetail);

	    	if(cartdetail && cartdetail.items && cartdetail.items.length > 0){
	    		return _dispatch({ ...state, cartdetail}, true, 'checkout_cart');
	    	} else {
	    		return _dispatch({ ...state, cartFinalInfo: {}, cartdetail: {}}, true, 'checkout_cart');
	    	}
	    	
	      	break;
	    case MANAGE_REPEAT_ITEM_CHECKOUT_CART:
	    	var {shopId, cartItemIndex} = action;
	    	var {cartdetail} = state;

	    	if(cartdetail.shopId == shopId){
	    		let items = [...cartdetail.items];
	    		items && items.length > 0 && items.map((obj, endex) => {
				    if(endex == cartItemIndex){
				    	obj.qty += 1;
				    	items[endex] = obj;
				    	cartdetail.items = [...items]; 
				    }
				})	
	    	} else {
	    		cartdetail = {shopId, items: [item]};
	    	}

	    	return _dispatch({ ...state, cartdetail: cartdetail}, true, 'qty-menu');
	    	//return { ...state, cartdetail};
	      	break;
	    case ADD_SUGGESTION:
	    	var {suggestion, index} = action;
	    	var {cartdetail} = state;
	    	//console.log(action.index, '999', action.suggestion);

	    	let items = [...cartdetail.items];
    		items && items.length > 0 && items.map((obj, endex) => {
			    if(endex == action.index){
			    	items[endex].suggestion = action.suggestion;
			    	cartdetail.items = [...items]; 
			    }
			})
	    	return { ...state, cartdetail};
	      	break;
	    case RIDER_SUGGESTION:
	    	var {riderSuggestion} = action;
	    	var {cartdetail} = state;
	    	cartdetail.riderSuggestion = riderSuggestion;
	    	return { ...state, cartdetail};
	      	break;
	    case ITEM_DETAIL_SUCCESS:
      		return _dispatch({ ...state, cartFinalInfo: action.payload}, true, 'cart_final_info');
      		break;
      	case SET_DELIVERED_ADDRESS:
      		var {address} = action;
	    	var {cartdetail} = state;
	    	cartdetail.addressId = action.addressId;
	    	return { ...state, cartdetail};
	      	break;
	    case REMOVE_ITEM_CHECKOUT_CART:
	    	var {shopId, index} = action;
	    	var {cartdetail} = state;
	    	if(cartdetail.shopId == shopId){
	    		let items = [...cartdetail.items];
	    		items && items.length > 0 && items.map((obj, endex) => {
				    if(endex == index){
				    	//delete items[endex];
				    	items.splice(endex, 1);
				    	cartdetail.items = [...items]; 
				    }
				})	
	    	} else {
	    		cartdetail = {shopId, items: [item]};
	    	}

	    	return { ...state, cartdetail};
	      	break;
	    case FINAL_PAYMENT_SUCCESS:
	    	return _dispatch({ ...state, cartFinalInfo: {}, cartdetail: {}});
	    	break;
	    case REORDER_SUCCESS:
	    	let ordDetail = action.payload;
	    	let detail = {};
	    	let detailItem = [];
	    	let itemObj = {};
	    	let uniqueId = '';
	    	detail = {...detail, 'shopId': ordDetail.restaurant && ordDetail.restaurant.shopId ? ordDetail.restaurant.shopId: null};

	    	ordDetail && ordDetail.items && ordDetail.items.length > 0 && ordDetail.items.map((obj, index) => {
	    		uniqueId = Date.now();
	    		itemObj = {...itemObj, id: obj.id, itemName: obj.itemName, isVeg: obj.isVeg, price: obj.price, finalPrice: obj.finalPrice,currencySymbol: obj.currencySymbol, isItemCustom: obj.isItemCustom, qty: obj.quantity, uniqueId: uniqueId};

	    		if(obj.variants){
	    			obj.variants.itemCustom && obj.variants.itemCustom.length > 0 && obj.variants.itemCustom.map((res, undex) => {
	    				let variants = {};
	    				if(res.isSelected == 1){
	    					variants = {...variants, id: res.id, name: res.name, price: res.price, finalPrice: res.finalPrice, isSelected: res.isSelected, currencyCode: res.currencyCode, currencySymbol: res.currencySymbol};
	    				}
	    				itemObj = {...itemObj, variants: variants};
	    			}) 
	    		} else {
	    			itemObj = {...itemObj, variants: null};
	    		}

	    		if(obj.atributes){
	    			let itemAtributes = [];
	    			obj.atributes && obj.atributes.length > 0 && obj.atributes.map((rec, endex) => {
	    				rec.itemCustom && rec.itemCustom.length > 0 && rec.itemCustom.map((cbj, cndex) => {
	    					let atributesItem = {};
	    					atributesItem = {...atributesItem, id: cbj.id, name: cbj.name, isVeg: cbj.isVeg, price: cbj.price, discount: cbj.discount, finalPrice: cbj.finalPrice, isSelected: cbj.isSelected, currencyCode: cbj.currencyCode, currencySymbol: cbj.currencySymbol};
	    					itemAtributes.push(atributesItem);
	    				})
	    			})
	    			itemObj = {...itemObj, atributes: itemAtributes};
	    		} else {
	    			itemObj = {...itemObj, atributes: null};
	    		}

	    		detailItem.push(itemObj);
	    	})

	    	detail = {...detail, items: detailItem};
	    	return _dispatch({ ...state, cartdetail: detail}, true, 'qty-menu');
	    	break;
	    case SET_MERCHANT_REFERENCE:
	    	var {cartdetail} = state;
	    	cartdetail.merchantReference = action.payload;
	    	return { ...state, cartdetail};
	    	break;
	    default:
	      return state;
  	}
}