import {
  ORDER_DETAIL,
  AVAILABLE_TIME_SLOTS,
  USER_SET_TIME_SLOT,
  CANCEL_SCHEDULE_TIME,
  REMOVE_INFO_ORDER_SUCCESS,
  DRIVER_LOCATION_SOCKET,
  _dispatch
} from '../actions/order';

const INITIAL_STATE = { orderDetail: {}, scheduledSlots:{}, userSelectedSlots: {}};

export default function(state = INITIAL_STATE, action) {
  switch(action.type) {

    case ORDER_DETAIL:
    	if(!(action.payload.status && action.payload.status == 3)){
    		return _dispatch({ ...state, orderDetail: action.payload}, true, 'order_detail');
    	}	
      return state;
      break;
    case AVAILABLE_TIME_SLOTS:
      return _dispatch({ ...state, scheduledSlots: action.payload}, true, 'available_time_slots');
      break;
    case USER_SET_TIME_SLOT:
      var {scheduledSlots} = state;
      let slots = [...scheduledSlots.scheduleDates];
      let dayindex = action.payload.dateIndex;
      let timIndex = action.payload.timeIndex;
      let userSelectedSlots = {};

      slots && slots.length > 0 && slots.map((obj, index) => {
        if(index == dayindex){
          userSelectedSlots = {...userSelectedSlots, slot_day: obj.date};
          obj.slots && obj.slots.length > 0 && obj.slots.map((tim, undex) => {
            if(undex == timIndex){
              userSelectedSlots = {...userSelectedSlots, slot_start_time: tim.scheduleStart};
              userSelectedSlots = {...userSelectedSlots, slot_end_time: tim.scheduleEnd};
            }
          })

        }
      })
      return _dispatch({ ...state, userSelectedSlots}, true, 'user_selected_time_slots');
      break;
    case CANCEL_SCHEDULE_TIME:
      return _dispatch({ ...state, userSelectedSlots: {}}, true, 'user_selected_time_slots');
      break;
    case REMOVE_INFO_ORDER_SUCCESS:
      return _dispatch({ ...state, userSelectedSlots: {}, orderDetail: {}});
      break;
    case DRIVER_LOCATION_SOCKET:
      if(state.orderDetail.order.orderId != action.payload.orderId){
        return state;
      }
      return _dispatch({ ...state, orderDetail: {...state.orderDetail, deliveryAgent: {...state.orderDetail.deliveryAgent, ...action.payload}}}, true, 'order_detail');
    break;
    default:
      return state;
  }
}