import {
  UPDATE_SERVICE_TYPE,
  _dispatch
} from '../actions/shop';


const INITIAL_STATE = {serviceType: 1};

export default function(state = INITIAL_STATE, action) {
  	switch(action.type) {
	    case UPDATE_SERVICE_TYPE:
	    	return _dispatch({ ...state, serviceType: action.payload ? action.payload : 1}, true, 'service_type');
	    	break;
	    default:
	      return state;
  	}
}