import React, { Component, Suspense } from 'react';
import { Switch, Route, BrowserRouter } from 'react-router-dom';
import {ROUTES} from './routes';
import ContentLoader from './components/ContentLoader';
import RenderRemoteComponent from './components/common/RenderRemoteComponent';
import GuestToken from './components/GuestToken';
import VerifyUserLocation from './components/VerifyUserLocation';
import Wrapper from './Wrapper';
import {ToastsContainer, ToastsStore, ToastsContainerPosition} from 'react-toasts';

class App extends Component {

  constructor(props){
    super(props);
  }
  
  render() {
    return (
        <>
          <GuestToken />
          <BrowserRouter>
            <Suspense fallback={<ContentLoader />}>
              <Wrapper />
            </Suspense>
          </BrowserRouter>
          <ToastsContainer store={ToastsStore} position={ToastsContainerPosition.TOP_RIGHT}/>
          <RenderRemoteComponent />
          <VerifyUserLocation />
        </>
    );
  }
}


export default App;