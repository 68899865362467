import {locale} from './translations.js';
import { CONFIG } from './constants';

/**
 * Translation
 *
 * @param      {<type>}  text    The text
 * @return     {<type>}  { description_of_the_return_value }
 */
export function _(text, params) {
	const language = window.localStorage.contentlanguage || CONFIG.contentLanguage;
	params = typeof params !== 'undefined' && params !== null ? params : [];

	if(typeof locale[language] !== 'undefined' && typeof locale[language][text] !== 'undefined'){
		text = locale[language][text];
	}
  
 	if(text.match(/\%s/)){
		params.map(p => {
			text = text.replace(/\%s/, p);
		});
	}
	
	return text;
}