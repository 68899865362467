import { createStore, applyMiddleware, compose } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import MemoryStorage from 'redux-persist-memory-storage';
import promise from "redux-promise-middleware";
import logger from "redux-logger";
import ReduxThunk from 'redux-thunk';
import axios from 'axios';
import storage from 'redux-persist/lib/storage';
import rootReducer from './reducers/index'; // the value from combineReducers
import {clearSession} from './utilities';
import {_} from './locale';
//import {toast} from 'react-toastify';
import {ToastsContainer, ToastsStore} from 'react-toasts';
import {ROUTES} from './routes';
import {ROOT, CONFIG, SESSION_EXPIRED} from './constants';
const CancelToken = axios.CancelToken;
const source = CancelToken.source();

const middleware = applyMiddleware(ReduxThunk, logger);

const persistConfig = {
	key: 'root',
	storage//: new MemoryStorage()
};

const pReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(pReducer, compose(middleware));
export const persistor = persistStore(store);

// Send access token in every request
axios.interceptors.request.use(function (config) {
  // Set processing
  if(typeof window.axiosThis !== 'undefined' && window.axiosThis){
    window.axiosThis.setState({
      processing: true
    });
  }

  // Explicit token
  if(typeof config.headers.Authorization === 'undefined'){
    const storeState = store.getState();
    if(storeState.Auth.user && storeState.Auth.user.token){
      config.headers = { ...config.headers, Authorization: `Bearer ${storeState.Auth.user.token}` };
    }
  }

  const language = window.localStorage.contentlanguage || CONFIG.contentLanguage;

  return {...config, headers: {...config.headers, contentlanguage: language, devicetype: CONFIG.deviceType, utcoffset: CONFIG.utcOffset}};
}, function (error) {
  // Set processing
  if(typeof window.axiosThis !== 'undefined' && window.axiosThis){
    window.axiosThis.setState({
      processing: false
    });
  }
  return Promise.reject(error);
});


// Add a response interceptor to check user session
// axios.interceptors.response.use(function (response) {
//   // Set processing
//   if(typeof window.axiosThis !== 'undefined' && window.axiosThis){
//     window.axiosThis.setState({
//       processing: false
//     });
//   }
//   return response;
// }, function (error) {
//   // Set processing
//   if(typeof window.axiosThis !== 'undefined' && window.axiosThis){
//     window.axiosThis.setState({
//       processing: false
//     });
//   }
//   // If session is unauthorised, then logout the user.
//   if(
//     typeof error.response !== 'undefined' 
//     && typeof error.response.status !== 'undefined' 
//     && error.response.status === 401){
//     // Redirect to login page
//     if(typeof error.response.config.verifyAuth === 'undefined' || error.response.config.verifyAuth === true){
//       toast.error(_('Your are logged out.'));
//       clearSession(`${ROOT}${ROUTES.ROOT.path}?redirectTo=${window.location.pathname}`);
      
//       return Promise.reject(error);
//     }
//   }

//   if(typeof error.response !== 'undefined'){
//     if(typeof error.response.config.hideError === 'undefined' || error.response.config.hideError != error.response.status){
//         const msg = typeof error.response.data.message !== 'undefined' ? error.response.data.message : error.response.data;
//         if(typeof msg == 'string'){
//           toast.error(_(msg));
//         }
//     }
//   }
//   // Do something with response error
//   return Promise.reject(error);
// });


// Add a response interceptor to check user session
axios.interceptors.response.use(function (response) {
  if(window.globalPageLoader !== null){
    window.globalPageLoader.setState({
      display: false
    });
  }
  return response;
}, function (error) {
  if(window.globalPageLoader !== null){
    window.globalPageLoader.setState({
      display: false
    });
  }
  // If session is unauthorised, then logout the user.
  if(
    typeof error.response !== 'undefined' 
    && typeof error.response.status !== 'undefined' 
    ){
    switch(error.response.status){
      case 401:
        //toast.error(SESSION_EXPIRED);
        clearSession();
        break;
      case 400:
        // Set form error toast here
        //console.log('testsssss');
        ToastsStore.error(error.response.data.message);
        //toast.error('Something wrong with your form fields value');
        break;
      default:
        // Set "Something went wrong" global error toast here
        ToastsStore.error('Something wrong, please try again');
    }
  }
  // Do something with response error
  return Promise.reject(error);
});






