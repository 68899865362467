import {
  SAVE_AUTH,
  UPDATE_USER_PHONENUMBER,
  _dispatch
} from '../actions/auth';

const INITIAL_STATE = {user: {}};

export default function(state = INITIAL_STATE, action) {
  switch(action.type) {

    case SAVE_AUTH:
      return _dispatch({ ...state, user: action.payload}, true, 'Auth');
      break;
    case "CLEARSESSION":
    	return _dispatch({ ...state, user: {}}, true, 'Auth');
      break;
    case UPDATE_USER_PHONENUMBER:
      return _dispatch({ ...state, user: {...state.user, countryCode: action.countryCode, phoneNumber: action.phoneNumber}}, true, 'Auth');
      break;
    default:
      return state;
  }
}