import axios from 'axios';
import { API_URL } from '../constants';
import {_dispatch} from '../utilities';
export {_dispatch};
export const UPDATE_GEOLOCATION = 'UPDATE_GEOLOCATION';
export const USER_ADDRESS_LIST = 'USER_ADDRESS_LIST';
export const ADD_NEW_ADDRESS_SUCCESS = 'ADD_NEW_ADDRESS_SUCCESS';
export const UPDATE_USER_ADDRESS_LOCATION = 'UPDATE_USER_ADDRESS_LOCATION';
export const DELETE_ADDRESS_SUCCESS = 'DELETE_ADDRESS_SUCCESS';
export const COUNTRY_CODE_LIST = 'COUNTRY_CODE_LIST';
export const UPDATE_ADDRESS_SUCCESS = 'UPDATE_ADDRESS_SUCCESS';

export function updateGeoLocation(data) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch({
	          type: UPDATE_GEOLOCATION,
	          payload: data
	        });

	        setTimeout(resolve, 100);
		});
	};
}

export function getUserAddreses(params) {
    return axios({
        method: 'GET',
        params: params,
        url: `${API_URL}/user/getAllAddress`
    });
}

export function getUserAddresesSuccess(payload){
	return {
      	type: USER_ADDRESS_LIST,
      	payload: payload
    }
}

export function addDeliveryAddress(props){
  return axios({
    method: 'POST',
    data: props,
    url: `${API_URL}/user/addAddress`
  });
}

export function addDeliveryAddressSuccess(payload) {
  return {
    type: ADD_NEW_ADDRESS_SUCCESS,
    payload: payload
  };
}

export function updateUserAddressLocation(payload){
  return {
    type: UPDATE_GEOLOCATION,
    payload: payload
  };
}

export function saveUserFirstStep(props){
  return axios({
    method: 'POST',
    data: props,
    url: `${API_URL}/user/signUp`
  });
}

export function resendVerificationCode(props){
  return axios({
    method: 'POST',
    data: props,
    url: `${API_URL}/user/sendPhoneVerificationCode`
  });
}

export function deleteAddress(data) {
  return axios({
    method: 'POST',
    data: data,
    url: `${API_URL}/user/deleteAddress`
  });
}
export function deleteAddressSuccess(payload) {
  return {
    type: DELETE_ADDRESS_SUCCESS,
    payload: payload
  };
}

export function getRatingOption(params){
  return axios({
    method: 'GET',
    params: params,
    url: `${API_URL}/user/getRatingOptions`
  });
}

export function rateOrder(props){
  return axios({
    method: 'POST',
    data: props,
    url: `${API_URL}/user/rateOrder`
  });
}

export function getWalletInfo(params){
  return axios({
    method: 'GET',
    params: params,
    url: `${API_URL}/user/getWalletDetails`
  });
}

export function postContactForm(data) {
  return axios({
    method: 'POST',
    data: data,
    url: `${API_URL}/user/postcontactform`
  });
}

export function getCountryCode(params) {
    return axios({
        method: 'GET',
        params: params,
        url: `${API_URL}/user/getCountries`
    });
}

export function getCountryCodeSuccess(payload){
  return {
    type: COUNTRY_CODE_LIST,
    payload: payload
  }
}

export function getAllLanguages(params) {
  return axios({
    method: 'GET',
    params: params,
    url: `${API_URL}/common/getalllanguages`,
  });
}

export function getFaq(params){
  return axios({
    method: 'GET',
    params: params,
    url: `${API_URL}/user/getFaq`
  });
}

export function rateFaq(data) {
  return axios({
    method: 'POST',
    data: data,
    url: `${API_URL}/user/rateFaq`
  });
}

export function verifyPhoneLogin(data){
  return axios({
    method: 'POST',
    data: data,
    url: `${API_URL}/user/phoneExist`
  });
}

export function sendEditPhoneVerificationCode(data) {
  return axios({
    method: 'POST',
    data: data,
    url: `${API_URL}/user/sendEditPhoneVerificationCode`
  });
}

export function editPhoneNumber(data) {
  return axios({
    method: 'POST',
    data: data,
    url: `${API_URL}/user/editPhoneNumber`
  });
}

export function cancelUserOrder(data) {
  return axios({
    method: 'POST',
    data: data,
    url: `${API_URL}/user/cancelorder`
  });
}

export function updateDeliveryAddressSuccess(payload, index) {
  return {
    type: UPDATE_ADDRESS_SUCCESS,
    payload: payload,
    index: index
  };
}

export function userLogout(props){
  return axios({
    method : 'POST',
    data : props,
    url: `${API_URL}/user/logout`
  })
}

export function sendForgotPasswordVerificationCode(data) {
  return axios({
    method: 'POST',
    data: data,
    url: `${API_URL}/user/sendForgotPasswordVerificationCode`
  });
}

export function forgotPassword(data) {
  return axios({
    method: 'POST',
    data: data,
    url: `${API_URL}/user/forgotPassword`
  });
}

export function changePassword(data) {
  return axios({
    method: 'POST',
    data: data,
    url: `${API_URL}/user/changepassword`
  });
}

export function deactivateAccountWeb(data) {
  return axios({
    method: 'DELETE',
    data: data,
    url: `${API_URL}/user/deactivateAccountWeb`
  });
}