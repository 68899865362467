import axios from 'axios';
import {store} from '../store';
import { API_URL } from '../constants';
import { _dispatch } from '../utilities';
export { _dispatch };
export  const UPDATE_SERVICE_TYPE = 'UPDATE_SERVICE_TYPE';


export function getShopDetail(params) {
    return axios({
        method: 'GET',
        params: params,
        url: `${API_URL}/user/getRestaurantDetails`
    });
}

export function fetchShops(params, servicetype) {
    const storeState = store.getState();
    servicetype = storeState.Shop.serviceType;
    return axios({
        method: 'GET',
        headers: {servicetype},
        params: params,
        url: `${API_URL}/user/getRestaurants`
    });
}

export function getPromoRestaurants(params) {
    return axios({
        method: 'GET',
        params: params,
        url: `${API_URL}/user/getCouponRestaurants`
    });
}

export function markShopFavourire(props) {
    return axios({
        method: 'POST',
        data: props,
        url: `${API_URL}/user/customerFavorites`
    });
}

export function userFavouriteShops(params) {
    return axios({
        method: 'GET',
        params: params,
        url: `${API_URL}/user/getFavorites`
    });
}

export function searching(params) {
    return axios({
        method: 'GET',
        params: params,
        url: `${API_URL}/user/searchRestaurant`
    });
}

export function markItemFavourite(props) {
    return axios({
        method: 'POST',
        data: props,
        url: `${API_URL}/user/markItemFavorite`
    });
}

export function getFavouriteItems(params) {
    return axios({
        method: 'GET',
        params: params,
        url: `${API_URL}/user/getFavoriteItems`
    });
}

export function getCuisineList(params) {
    return axios({
        method: 'GET',
        params: params,
        url: `${API_URL}/user/getCuisine`
    });
}

export function getUserAvailabeSection(params){
    return axios({
        method: 'GET',
        params: params,
        url: `${API_URL}/user/getUserAvailableSection`
    });
}

export function getCategoryRestaurants(params) {
    return axios({
        method: 'GET',
        params: params,
        url: `${API_URL}/user/getCategoryRestaurants`
    });
}

export function bannerGallery(params){
    return axios({
        method: 'GET',
        params: params,
        url: `${API_URL}/user/getUserGallery`
    });
}

export function addRestaurantPatner(props) {
    return axios({
        method: 'POST',
        data: props,
        url: `${API_URL}/user/addRestaurantPatner`
    });
}

export function getRestaurantFeedback(params){
    return axios({
        method: 'GET',
        params: params,
        url: `${API_URL}/user/getRestaurantFeedback`
    });
}

export function addRiderPatner(props) {
    return axios({
        method: 'POST',
        data: props,
        url: `${API_URL}/user/addRiderPatner`
    });
}


export function updateBusinessType(payload){
    return{
        type: UPDATE_SERVICE_TYPE,
        payload: payload
    }
}