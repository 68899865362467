import React, { Component, Suspense } from 'react';
import {connect} from 'react-redux';
import * as io from "socket.io-client";
import {updateOrderStatusSocket, driverLocationSocket } from '../actions/order';

class VerifyUserLocation extends Component {
  constructor(props){
    super(props);
    this.socket = null;
    //this.socket = io.connect(`54.215.121.37:3000?token=${this.props.user.token || ' '}`);
    //this.socket = io.connect(`dev.gatoes.com:3001?token=${this.props.user.token || ' '}`);
    this.socket = io.connect(`https://gatoes.com:3001?token=${this.props.user.token || ' '}`);
  }

  componentDidMount(){
    if(this.socket === null)
      return;

    this.socket.on("error", message => {
      //console.log('error',message);
    });
    this.socket.on("disconnect", message => {
      //console.log('disconnect',message);
    });
    this.socket.on("connecting", message => {
      //console.log('connecting',message);
    });
    this.socket.on("errorfromserver", message => {
      //console.log('error',message);
    });
    this.socket.on("updateStatusSocket", message => {
      //console.log('success socket',message);
      this.props.updateOrderStatusSocket(message);
    });

    this.socket.on("driverLocation", message => {
      //console.log('success socket',message);
      this.props.driverLocationSocket(message);
    });
  }
  shouldComponentUpdate(nextProps, nextState){
    return false;
  }
  render() {

    return null;
  }
}

const mapStateToProps = (state) => {
  return {
    user: {...state.Auth.user},
    status: state.Auth.status,
    compName: state.Auth.compName
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateOrderStatusSocket: (payload) => {
      dispatch(updateOrderStatusSocket(payload));
    },
    driverLocationSocket: (payload) => {
      dispatch(driverLocationSocket(payload));
    }
  };
}


export default connect(mapStateToProps, mapDispatchToProps)(VerifyUserLocation);