export const locale = {
	ar: {
		'Edit Delivery Address' : 'تعديل عنوان التسليم',
		'Add Suggestion': 'إضافة اقتراح',
		'PROCEED': 'تقدم',
		'Any Suggestions for riders? We will pass it on…': 'أي اقتراحات للسائقين؟ سننقلها ...',
		'Bill Details': 'تفاصيل الفاتورة',
		'Item Total': 'مجموع العناصر',
		'Coupon': 'كوبون',
		'Offer Discount': 'عرض الخصم',
		'Delivery Charges': 'رسوم التوصيل',
		'Wallet': 'المحفظة',
		'Total Amount': 'المجموع الكامل',
		'Items': 'عناصر',
		'items': 'عناصر',
		'item': 'العناصر',
		'Good food is always cooking! Go ahead, order some yummy items from the menu.': 'الطعام الجيد دائما يطهى! امضي قدما ، واطلب بعض الطعام اللذيذ من القائمة.',
		'Available Coupons': 'الكوبونات المتاحة',
		'ADD': 'إضافة',
		'Choose a Delivery Address': 'اختيار عنوان التسليم',
		'Change': 'تغيير',
		'mins': 'دقائق',
		'Mins': 'دقائق',
		'Deliver here': 'توصيل هنا',
		'Payment': 'الدفع',
		'Pay on Delivery': 'الدفع عند الاستلام',
		'Cash on delivery': 'الدفع عند الاستلام',
		'Pay once you got order at you home': 'ادفع بمجرد حصولك على الطلب في المنزل',
		'Pay': 'إدفع',
		'Payment': 'الدفع',
		'Delivery address': 'عنوان التسليم',
		'Logged in': 'تسجيل الدخول',
		'Account': 'الحساب',
		'To place your order now, log in to your existing account or sign up.': 'لتقديم طلبك الآن ، قم بتسجيل الدخول إلى حسابك الحالي أو تسجيل حساب جديد.',
		'Use Wallet': 'استخدام المحفظة',
		'Available': 'متاح',
		'We are not here yet!': 'نحن لسنا هنا بعد!',
		'Sorry! We can not deliver food at this location. Why don’t you try a different location.': 'آسف! لا يمكننا توصيل الطعام الى هذا الموقع. لماذا لا تجرب موقعًا مختلفًا. ',

		'Cart is Empty': 'السلة خالية',
		'Looks like you have’nt made your choices yet… Add Something': 'يبدو أنك لم تحدد اختياراتك بعد ... أضف شيئًا ما',
		'BROWSE RESTAURANTS': 'بحث عن المطاعم',
		'Gatoes is an online platform curated for your hunger needs. Order from a wide range of restaurants and cafes.' : 'فينجيرتيبس هي عبارة عن منصة على الإنترنت لرعاية احتياجات الجوع الخاصة بك. اطلب من مجموعة واسعة من المطاعم والمقاهي.',
		'Information': 'معلومات',
		'About us': 'معلومات عنا',
		'Career': 'مهنة',
		'Help & Support': 'مساعدة و دعم',
		'Contact us': 'تواصل معنا',
		'Faq': 'Faq',
		'Terms & Conditions': 'البنود و الظروف',
		'Refund & Cancellation': 'إعادة مال و إلغاء',
		'Privacy Policy': 'سياسة خاصة',
		'Cookie Policy': 'Cookie Policy',
		'Gatoes. All Rights Reserved': 'فينجيرتيبس.كل الحقوق محفوظة',
		'My Account': 'حسابي',
		'Order History': 'سجل الطلبات',
		'Wish List': 'قائمة الرغبات',
		'You have blocked Gatoes from tracking your location. To use this, change your location settings in browser.': 'لقد حظرت فينجيرتيبس من تتبع موقعك. لاستخدام هذا ، قم بتغيير إعدادات موقعك في المتصفح.',
		'Your location information is unavailable.': 'معلومات موقعك غير متوفرة.',
		'The request to get user location timed out.': 'طلب الحصول على موقع المستخدم انتهت مهلته.',
		'An unknown error occurred.': 'حدث خطأ غير معروف.',
		'My Account': 'حسابي',
		'Logout': 'تسجيل الخروح',
		'login': 'تسجيل الدخول',
		'Login': 'تسجيل الدخول',
		'LOG IN': 'تسجيل الدخول',
		'ASAP': 'في اسرع وقت ممكن',
		'Schedule Order': 'حجز الطلب',
		'Schedule delivery time': 'حدد وقت التسليم',
		'SAVE': 'حفظ',
		'SAVED ADDRESSES': 'العناوين المحفوظة',
		'WELCOME Back': 'اهلا بك مجددا',
		'Log in to your': 'تسجيل الدخول إلى',
		'Gatoes': 'فينجيرتيبس',
		'account': 'الحساب',
		'Mobile Number': 'رقم الجوال',
		'PROCEED': 'تقدم',
		'SIGN UP': 'تسجيل ',
		'Don’t have an account?': 'ليس لديك حساب؟',
		'SIGN UP': 'التسجيل',
		'By creating an account, you agree to Gatoes': 'بإنشاء حساب ، أنت توافق على فينجيرتيبس',
		'Terms of Service': 'شروط الخدمة',
		'Cookie Policy': 'Cookie Policy',
		'Content Policies': 'سياسات المحتوى',
		'Already have an account?': 'هل لديك حساب؟',
		'Places': 'أماكن',
		'Verify Details': 'التحقق من التفاصيل',
		'Verification code has been sent to your phone number': 'تم إرسال رمز التحقق إلى رقم هاتفك',
		'OTP': 'OTP',
		'not received yet?': 'لم تتلق بعد؟',
		'Resend Code': 'إعادة إرسال الرمز',
		'VERIFY': 'تحقق',
		'Frequently asked questions': 'أسئلة مكررة',
		'Was this article helpful?': 'هل ساعدك هذا المقال؟',
		'Get the app. Order with a tap.': 'احصل على التطبيق طلب بنقرة واحدة.',
		'Download the app Now! Available on:': 'قم بتنزيل التطبيق الآن! متاح على:',
		'Your favourite': 'المفضل لديك',
		'How easy it is': 'كم هو سهل',
		'Explore from a wide range of restaurants and experience the best food delivery services in Qatar.': 'استكشف من مجموعة واسعة من المطاعم وجرب أفضل خدمات توصيل الطعام في قطر.',
		'Order your favourite food': 'اطلب طعامك المفضل',
		'Order food from your favorite restaurants and get your food delivered to you in no time.': 'اطلب طعامًا من مطاعمك المفضلة واحصل على طعامك في أي وقت من الأوقات.',
		'Track your food': 'تتبع طعامك',
		'Keep a constant track of your order with timely notifications of the rider.': 'تتبع طلبك من إشعارات السائق',
		'Enjoy your meal!': 'أتمنى لك وجبة شهية!',
		'Get your order delivered on time and relish your favorite meal with everyone. .': 'احصل على طلبك في الوقت المحدد واستمتع بوجبتك المفضلة مع الجميع. .',
		'Sorry, page not found': 'عذرا، لم يتم العثور على الصفحة',
		'Order Summary': 'ملخص الطلب',
		'Packaging Charges': 'رسوم التعبئة والتغليف',
		'Delivery Charge': 'رسوم التوصيل',
		'Wallet Used': 'محفظة مستعملة',
		'Promo Discount': 'خصم الترويجي',
		'Total Amount': 'المبلغ الإجمالي',
		'Paid via': 'الدفع عبر',
		'GET HELP?': 'احصل على مساعدة؟',
		'Cancel': 'إلغاء',
		'Order #': 'طلب #',
		'ORDER #': 'طلب #',
		'Order Rating': 'تقييم الطلب',
		'Food': 'الطعام',
		'Delivery': 'التوصيل',
		'Packing': 'التعبئة',
		'Order has been ': 'الطلب تم ',
		'Your food is prepared': 'طعامك جاهز',
		'Your food is preparing': 'طعامك يجهز',
		'Tasty Food is Enroute!': 'الطعام اللذيذ في الطريق',
		'Contact us': 'تواصل معنا',
		'All inquiries and comments related to our services and activities related to our website are welcomed to address to Gatoes Contracting and Transportation.': 'يتم الترحيب بجميع الاستفسارات والتعليقات المتعلقة بخدماتنا وأنشطتنا المتعلقة بموقعنا على الويب للتوجيه إلى فينجيرتيبس المقاولات والنقل.',
		'For questions and concerns please reach out to the below.': 'للأسئلة يرجى التواصل مع أدناه.',
		'Phone number': 'رقم الجوال',
		'Email': 'البريد الإلكتروني',
		'Write to us': 'إكتب لنا',
		'We would love to hear from you!': ' نحب أن نسمع منك!',
		'Full Name': 'الإسم الكامل',
		'Your Message': 'رسالتك',
		'Send': 'إرسال',
		'Restaurants': 'المطاعم',
		'restaurants': 'المطاعم',
		'Dishes': 'الأطباق',
		'Veg only': 'نباتي فقط',
		'Egg only': 'بيض فقط',
		'OFF': 'غير متاح',
		'New': 'جديد',
		'Next Open': 'فتح التالي',
		'for two': 'لشخصين',
		'SUGGESTIONS': 'اقتراحات',
		'Most Ordered': 'الأكثر طلبا',
		'for two . Deliver in': 'لشخصين. التوصيل في',
		'Customisable': 'تخصيص',
		'Results for': 'نتائج ل',
		'Results': 'النتائج',
		'Trending': 'الشائع',
		'OFF': 'غير متاح',
		'Your Previous Customisations': 'التخصيصات السابقة الخاصة بك',
		'I’LL CHOOSE AGAIN': 'سأختار مرة أخرى',
		'REPEAT LAST': 'كرر السابق',
		'Add For': 'أضف ل',
		'Not available': 'غير متاح',
		'Next available on ': 'التالي متاح في ',
		'Filter restaurants': 'تصفية المطاعم',
		'Cuisines': 'المطابخ',
		'More Filters': 'المزيد من التصفيات',
		'Offers': 'عروض',
		'APPLY FILTERS': 'تطبيق التصفيات',
		'Deliver in': 'توصيل في',
		'Minimum Order': 'الحد الأدنى للطلب',
		'Overall Rating': 'تقييم عام',
		'OFFERS': 'عروض',
		'Next Available': 'التالي متاح',
		'Order now': 'اطلب الان',
		'Filters': 'التصفيات',
		'CLEAR': 'مسح',
		'Relevance': 'ملاءمة',
		'Rating': 'تقييم',
		'Delivery Time': 'وقت التسليم',
		'Cost': 'كلفة',
		'Back to all order': 'العودة إلى كل الطلبات',
		'Add Delivery Address': 'إضافة عنوان التسليم',
		'Enter Location': 'إدخال العنوان',
		'Building Zone': 'منطقة المبنة',
		'Street Number': 'رقم الشارع',
		'Home': 'المنزل',
		'Work': 'عمل',
		'Other': 'آخر',
		'HOME': 'المنزل',
		'WORK': 'عمل',
		'OTHER': 'آخر',
		'Orders': 'الطلبات',
		'Saved Restaurants': 'المطاعم المحفوظة',
		'Favourite Dishes': 'الأطباق المفضلة',
		'Manage Addresses': 'إدارة العناوين',
		'Wallet': 'المحفظة',
		'Manage Profile': 'إدارة الملف الشخصي',
		'Order Delivered': 'تم تسليم الطلب',
		'Rate delivery partner': 'تقييم السائق ',
		'Poor': 'ضعيف',
		'Excellent': 'ممتاز',
		'Good': 'جيد',
		'What can we improve?': 'ما الذي يمكننا تحسينه?',
		'Rate the food': 'تقييم الطعام',
		'What can we improve?': 'ما الذي يمكننا تحسينه؟',
		'How was the packing?': 'كيف كان التغليف؟',
		'Any comment (Optional)': 'أي تعليق (اختياري)',
		'SUBMIT': 'إرسال',
		'No Saved Addresses': 'لا توجد عناوين محفوظة',
		'You don’t have any addresses saved. Saving address helps you checkout faster.': 'ليس لديك أي عناوين محفوظة. حفظ العنوان يساعدك على الدفع بشكل أسرع.',
		'ADD NEW ADDRESS': 'إضافة عنوان جديد',
		'edit': 'تعديل',
		'Delete': 'إلغاء',
		'No favourite items': 'لا توجد عناصر مفضلة',
		'You haven’t set any items favourite yet.': 'لم تقم بتعيين أي عناصر مفضلة حتى الآن.',
		'No Ongoing Orders': 'لا توجد طلبات جارية',
		'Looks like you have’nt ordered anything yet… Order Now!': 'يبدو أنك لم تطلب أي شيء حتى الآن ... اطلب الآن!',
		'ORDER': 'طلب',
		'Total paid:': 'مجموع المبالغ المدفوعة:',
		'View Details': 'عرض التفاصيل',
		'Track order': 'تتبع الطلب',
		'My Orders': 'طلباتي',
		'Ongoing': 'جاري',
		'Past': 'السابق',
		'No Past Orders': 'لا طلبات سابقة',
		'Looks like you have’nt ordered anything yet… Order Now!': 'يبدو أنك لم تطلب أي شيء حتى الآن ... اطلب الآن!',
		'ORDER NOW': 'إطلب الان',
		'Delivered': 'تم السليم',
		'on': 'متاح',
		'Total paid:': 'مجموع المبالغ المدفوعة:',
		'View Details': 'عرض التفاصيل',
		'Rated': 'قيم',
		'Rate order': 'تقييم الطلب',
		'Reorder': 'إعادة الطلب',
		'Saved Restaurants': 'المطاعم المحفوظة',
		'No Saved Restaurants': 'لا توجد مطاعم محفوظة',
		'You haven’t saved any restaurants yet.': 'لم تقم بحفظ أي مطاعم حتى الآن.',
		'BROWSE RESTAURANTS': 'البحث عن المطاعم',
		'OFF': 'غير متاح',
		'Wallet balance': 'رصيد المحفظة',
		'Transaction history': 'سجل التحويلات',
		'Verification code has been sent successfully.': 'تم إرسال رمز التحقق بنجاح.',
		'Enter verification code': 'أدخل رمز التحقق',
		'Thanks, your rating has been done successfully.': 'شكرا ، لقد تم تقييمك للطلب بنجاح.',
		'Do you want to cancel this order?': 'هل تريد إلغاء هذا الطلب؟',
		'Order has been cancelled successfully.': 'تم إلغاء الطلب بنجاح.',
		'Your message has been sent successfully.': 'تم ارسال رسالتك بنجاح.',
		'No result matches' : 'لا توجد نتائج مطابقة',
		'No Results found. Please check the spelling or try a different restaurant.': 'لم يتم العثور على نتائج. يرجى التحقق من الإملاء أو تجربة مطعم آخر.',
		'No Results found. Please check the spelling or try a different items.': 'لم يتم العثور على نتائج. يرجى التحقق من الإملاء أو تجربة عناصر مختلفة.',
		'Thanks, your rating has been done successfully.': 'شكرا ، لقد تم تقييمك للطلب بنجاح',
		'Do you really want to delete this!' : 'هل تريد حقًا حذف هذا!',
		'Record has been deleted successfully': 'تم حذف السجل بنجاح',
		'Please enter your location': 'يرجى إدخال موقعك',
		'Discover tasty food' : 'استكشف الطعام اللذيذ',
		'delivered straight to your door': 'يوصل مباشرة الى باب بيتك',
		'Faq': 'التعليمات',
		'Enter your delivery location': 'أدخل موقع التوصيل الخاص بك',
		'Find Food': 'بحث عن طعام',
		'Search for restaurants': 'بحث عن مطاعم',
		'Search for dishes': 'بحث عن اطباق',
		'view cart': 'عرض العربة',
		'Go to checkout': 'اذهب للدفع',
		'Cart': 'عربة',
		'coupon applied successfully': 'تم تطبيق الكوبون بنجاح ',
		'Apply Coupon': 'تطبيق الكوبون',
		'APPLY COUPON': 'تطبيق الكوبون',
		'Apply': 'تطبيق',
		'APPLY': 'تطبيق',
		'Add New': 'اضف جديد',
		'Cookie Policy': 'سياسة سجل التتبع',
		'Legal': 'قانوني',
		'Locate Me': 'حدد موقعي',
		'Locating...': 'تحديد الموقع',
		'FLASH': 'فلاش',
		'Introducing the all new FLASH delivery': 'تقديم توصيل فلاش الجديد',
		'Look for the': 'إبحث على',
		'Icon to get': 'رمز الحصول',
		'Live tracking': 'تتبع مباشر',
		'Fast delivery': 'توصيل سريع',
		'Trusted drivers': 'السائقين موثوق بهم',
		'View all': 'عرض الكل',
		'to': 'إلى',
		'Preparing':  'تحضير',
		'Canceled by user': 'تم الالغاء بواسطة المستخدم',
		'Canceled by admin':  'تم الالغاء بواسطة المشرف',
		'Canceled by shop': 'تم الالغاء من قبل المتجر',
		'Marked ready': 'جاهز',
		'On the way': 'علی الصریق',
		'Out of stock': 'غير متاح',
		'Scheduled': 'تم الحجز',
		'Refund': 'اعادة المال',
		'Payment failed': 'عملية الدفع لم تنجح',
		'Search for restaurants or items': 'البحث على المطاعم و العناصر',
		'Full menu': 'قائمة الطعام',
		'Check out': 'الدفع',
		'Otp': 'كلمة السر لمرة واحدة',
		'Checkout': 'الدفع',
		'Enter coupon code': 'ادخل رقم الكوبون',
		'Skip': 'تخطى',
		'Order confirmed': 'تم تأكيد الطلب',
		'Waiting for confirmation': 'إنتظار التأكيد',
		'Your order has been prepared': 'لقد تم تجهيز طلبك',
		'Your order is being preparing': 'يتم تجهيز طلبك',
		'Place Order': 'اطلب',
		'Do you want to proceed with the order?': 'هل تريد متابعة الطلب؟',
		'Credit Card': 'بطاقة الائتمان',
		'Pay using Credit Card': 'الدفع باستخدام بطاقة الائتمان',
		'Online Payment': 'الدفع الالكتروني',
		'Payment Pending': 'انتظار الدفع',
		'Thanks for ordering, enjoy your order': 'شكرا للطلب، استمتع بطلبك'
	}
}