import { API_URL } from '../constants';
import {_dispatch, _axios} from '../utilities';
export {_dispatch};
export const SAVE_AUTH = 'SAVE_AUTH';
export const UPDATE_USER_PHONENUMBER = 'UPDATE_USER_PHONENUMBER';

export function login($this, data) {
  return (dispatch) => { 
    return _axios({
      method: 'POST',
      data,
      url: `${API_URL}/user/login`
    }, $this).then((response) => {
      // Successful login
      dispatch({
        type: SAVE_AUTH,
        payload: response.data.data
      });
    });
  };
}



// export function verify(credentials){
  
//   return (dispatch) => { 
//     const request = axios({
//       method: 'POST',
//       data: credentials,
//       url: `${API_ROOT}/user/login`,
//     }).then((response) => dispatch({
//         type: LOGIN_SUCCESS,
//         payload: response.data.payload
//     })).catch((error) => dispatch({
//         type: LOGIN_ERROR,
//         payload: error.response.data.error
//     }));
//   };
// }

export function logout($this){
   return (dispatch) => { 
 //  	dispatch({
	//     type: SAVE_AUTH,
	//     payload: {}
	// });
    return _axios({
      method: 'GET',
      url: `${API_URL}/user/getGuestToken`
    }, $this).then((response) => {
      dispatch({
        type: SAVE_AUTH,
        payload: response.data.data
      });
    });
  }
}

export function createGuestToken(params){
  return (dispatch) => { 
    // dispatch({
    //   type: SAVE_AUTH,
    //   payload: {}
    // });
    return _axios({
      method: 'GET',
      params: params,
      url: `${API_URL}/user/getGuestToken`
    }).then((response) => {
      dispatch({
        type: SAVE_AUTH,
        payload: response.data.data
      });
    });
  }
}

export function registerUser($this, data) {
  return (dispatch) => { 
    return _axios({
      method: 'POST',
      data,
      url: `${API_URL}/user/signUp`
    }, $this).then((response) => {
      // Successful login
      if(response.data.data && response.data.data.token){
        dispatch({
          type: SAVE_AUTH,
          payload: response.data.data
        });
      }
    });
  };
}

export function updateUserPhoneNumber(countryCode, phoneNumber) {
  return {
    type: UPDATE_USER_PHONENUMBER,
    countryCode: countryCode,
    phoneNumber: phoneNumber
  };
}

