import { lazy } from 'react';
import axios from 'axios';
import {store} from './store';
import moment from 'moment';
import {ROUTES} from './routes';

export const APP_VERSION = '1.0';

export function load(component){

  return lazy(() => import(`./components/${component}`));
}

export function fromNow(dateTime){
  return moment.utc(dateTime).fromNow();
}

export function utcToLocal(dateTime, format = 'DD-MM-YYYY'){
  return moment.utc(dateTime).local().format(format);
}

export function clearSession(){
  const {dispatch} = store;
  localStorage.removeItem('persist:root');
  localStorage.removeItem('currencyCode');
  localStorage.removeItem('currencySymbol');
  localStorage.removeItem('auth');
  dispatch({
    type: 'CLEARSESSION'
  })
  // localStorage.removeItem('User');
  // localStorage.removeItem('Cart');
  // localStorage.removeItem('Order');
  // redirect on root
  
  window.location = '/logout';
}

export function verifyAndUpdateAppVersion(){
  // Display App Version
  //console.log('APP VERSION', APP_VERSION);

  const version = localStorage.getItem("APP_VERSION");
  if(version === null || version != APP_VERSION){
    localStorage.setItem("APP_VERSION", APP_VERSION);
    clearSession();
  }
}

export function _dispatch(nextState, rerender = false, compName = null) {
  rerender = rerender
    ? new Date().getTime()
    : nextState.status;
  return {
    ...nextState,
    status: rerender,
    compName
  }
}

export function authentication(){
  // With auth token
  if(typeof localStorage.getItem('jwtToken') !== 'undefined' && localStorage.getItem('jwtToken') !== null){
    return true;
  }

  return false;
}

export function getUser(){
  // With auth token
  if(typeof localStorage.getItem('auth') !== 'undefined' && localStorage.getItem('auth') !== null){
    return JSON.parse(localStorage.getItem('auth'));
  }

  return null;
}

export function getCurrencySymbol(){
  // With auth token
  if(typeof localStorage.getItem('currencySymbol') !== 'undefined' && localStorage.getItem('currencySymbol') !== null){
    return localStorage.getItem('currencySymbol');
  }

  return null;
}

export function getCurrencyCode(){
  // With auth token
  if(typeof localStorage.getItem('currencyCode') !== 'undefined' && localStorage.getItem('currencyCode') !== null){
    return localStorage.getItem('currencyCode');
  }

  return null;
}

export function getDieteryStatus(){
  // With auth token
  if(typeof localStorage.getItem('dieterystatus') !== 'undefined' && localStorage.getItem('dieterystatus') !== null){
    return localStorage.getItem('dieterystatus');
  }

  return 1;
}

export function getAclChecks(permissionType){
  var result = 0;
  //console.log('aaaaaaaaaa', localStorage.getItem('hasAllAccess'));
  if(localStorage.getItem('hasAllAccess') == 'true'){
    result = 1;
  } else {
    var userpermissions = JSON.parse(localStorage.getItem('permissions'));
    userpermissions && userpermissions.length > 0 && userpermissions.map((obj, index) => (
      permissionType == obj ? result = 1 : null
    ))
  }
  return result;
}

export function _axios(config, $this){
  // Stop execution of redundant requests
  if($this.state && $this.state.processing){
    return new Promise((resolve, reject) => {});
  }
  // For dummy requests
  if($this.state && $this.state.dummy){
    return new Promise((resolve, reject) => {resolve($this.state.dummy)});
  }
  window.axiosThis = $this;

  return axios(config)
}

export function getIdFromSlug(slug){
  var result = slug.split('-');
  var cnt = result.length;
  var id = result[cnt-1];
  return id;
}

export function changeCuisineFormat(cuisines){
  // var text = cuisines;
  // var result = text.replace(',', ' ·');
  // return result;
}


export function getItemsLastAddedDetail(itemId, detail){
  let result = [];
  let i = 1;
  var reverseDet = detail.items;
  var itemIndex = null;
  var latestChoice = '';
  //reverseDet = reverseDet.reverse();
  reverseDet.length > 0 && reverseDet.map((obj, index) => {
    if(obj.id == itemId && i == reverseDet.length){
      //console.log('1231', obj);
      if(obj.variants && obj.variants.name){
        latestChoice += obj.variants && obj.variants.name + ", "
      }
      obj.atributes.length > 0 && obj.atributes.map((attr, undex) => (
        latestChoice += attr.name + ', '
      ))
      itemIndex = index;
    }
    i++
  })
  latestChoice = latestChoice.slice(0, -2);
  result = [latestChoice, itemIndex];
  return result;
}

export function checkNewCustomizeRequest(newItems, detail){
  let i = 0;
  let flag = 0;
  let itemAttr = [];
  let matchIndex  = null

  //get attribute array new items
  newItems.atributes && newItems.atributes.length > 0 && newItems.atributes.map((atr, endex) => {
    itemAttr.push(atr.id)
  })

  detail.length > 0 && detail.map((obj, index) => {
    if(obj.id == newItems.id && flag == 0){
      let detailAttr = [];
     
      //get attribute array detail
      obj.atributes && obj.atributes.length > 0 && obj.atributes.map((attr, undex) => {
        detailAttr.push(attr.id)
      })

      var result_attribute = window.$$(itemAttr).not(detailAttr).get();
      var result_attribute_second = window.$$(detailAttr).not(itemAttr).get();

      if(result_attribute_second == 0  && result_attribute.length == 0 && ((obj.variants && obj.variants.id) == (newItems.variants && newItems.variants.id))){
        matchIndex = index
        flag++;
      }
    }
  })

  var matchResult = [flag, matchIndex];
  return matchResult;
}

export function getCartItemQuantity(newItems, detail){
  let i = 0;
  let flag = 0;
  let itemAttr = [];
  let qty = 0;
  //get attribute array new items
  newItems.atributes && newItems.atributes.length > 0 && newItems.atributes.map((atr, endex) => {
    itemAttr.push(atr.id)
  })

  detail.length > 0 && detail.map((obj, index) => {
    if(obj.id == newItems.id && flag == 0){
      let detailAttr = [];
     
      //get attribute array detail
      obj.atributes && obj.atributes.length > 0 && obj.atributes.map((attr, undex) => {
        detailAttr.push(attr.id)
      })

      var result_attribute = window.$$(itemAttr).not(detailAttr).get();
      var result_attribute_second = window.$$(detailAttr).not(itemAttr).get();

      if(result_attribute_second == 0  && result_attribute.length == 0 && ((obj.variants && obj.variants.id) == (newItems.variants && newItems.variants.id))){
        qty = obj.qty;
        flag++;
      }
    }
  })
  
  return qty;
}

export function checkCustomizedItemNumber(newItems, detail){
  let qty = 0;
  const result = detail.map(item => {
      if(item.id == newItems.id){
        qty += 1;
      }
  });
    return qty;
}

// get string of added items variants and addons
export function getVariantAddonsItemString(obj){
  var result = '';

  if(obj.variants && obj.variants.name){
    result += obj.variants.name + ", "
  }
  obj.atributes && obj.atributes.length > 0 && obj.atributes.map((attr, undex) => (
    result += attr.name + ', '
  ))
  return result.slice(0, -2);
}

//format for cart items that go to server for detail
export function finalCartInfoDetail(cartdetail){
  let detail = [];
  cartdetail && cartdetail.items && cartdetail.items.length > 0 && cartdetail.items.map((obj, index) => {
    let info = {};
    info.id = obj.id;
    info.quantity = obj.qty;

    if(obj.suggestion){
      info.suggestion = obj.suggestion;
    } else {
      info.suggestion = '';
    }

    if(obj.variants){
      info.varientId = obj.variants.id
    } else {
      info.varientId = '';
    }

    if(obj.atributes){
      let attribueArr = [];
      obj.atributes.length > 0 && obj.atributes.map((attr, undex) => {
        attribueArr.push(attr.id);
      })
      info.custItem = attribueArr;
    } else {
      info.custItem = null;
    }
    detail.push(info);
  })
  return detail;

}

export function shopCurrentStatus(detail){
  //console.log('stats', detail);
  let msg = '';
  if(detail && detail.isOutOfRegion){
    msg = "Could not deliver to selected address.";
  } else if((detail && detail.isTemporaryClosed) || (detail && !detail.isOpen) || (detail && !detail.isDelivering)){
    if(detail && detail.nextOpenAt){
      msg = moment.utc(detail.nextOpenAt).local().format('llll');
    } else {
      msg = "Closed";
    }
  }
  return msg;
}

export function checkItemAvailabilityStatus(item, data){
  let msg = '';
  data && data.length > 0 && data.map((obj, index) => {
    if(item.id == obj.id){
      if(!obj.isCategoryAvailable){
        msg = 'Not available';
      } else if(!obj.availability){
        if(obj.nextAvailableOn){
          msg = moment.utc(obj.nextAvailableOn).local().format('lll');
        }
      } else {
        msg = '';
      }

    }
  })
  return msg;
}

export function selectedSlotInfo(scheduledSlots, dayIndex, timeIndex){
  let detail = {};
  scheduledSlots && scheduledSlots.scheduleDates && scheduledSlots.scheduleDates.length > 0 && scheduledSlots.scheduleDates.map((obj, index) => {
    if(index == dayIndex){
      obj.slots && obj.slots.length > 0 && obj.slots.map((tim, undex) => {
        if(undex == timeIndex){
          detail = {...detail, slot_start_time: tim.scheduleStart};
          detail = {...detail, slot_end_time: tim.scheduleEnd};
        }
      })

    }
  })
  return detail;
}

// export function isUserLocationExist() {
//   let user = localStorage.getItem("User");
//   console.log('77777', user);
// }

export function loadScript(callback, scriptId, jsSrc, callbackParams = null, attrs = {}) {
  const existingScript = document.getElementById(scriptId);
  if(!existingScript) {
    const script = document.createElement('script');
    script.src = jsSrc;
    script.id = scriptId;
    if(Object.keys(attrs).length > 0){
      Object.keys(attrs).map(key => {
        script.setAttribute(key, attrs[key]);
      });
    }
    document.body.appendChild(script);
    script.onload = () => {
      if (callback) callback(callbackParams);
    };
  }
  if (existingScript && callback) callback(callbackParams);
}

export function unLoadScript(scriptId) {
  const existingScript = document.getElementById(scriptId);
  if (existingScript) {
    window.$$(existingScript).remove();
  }
}