import React, { Component, Suspense } from 'react';
import { Route, Redirect } from 'react-router-dom';
import {connect} from 'react-redux';
import {logout} from '../actions/auth'; 


class GuestToken extends Component {
  constructor(props){
    super(props);
  }

  componentWillMount(){
    if(!this.props.userdetail.token){
      this.props.logout(this);
    }
  }
  
  render() {
    return (
      <div></div>

    );
  }
}

const mapStateToProps = (state) => {
  return {
    userdetail: {...state.Auth.user},
  }
}

export default connect(mapStateToProps, {logout})(GuestToken);