import {load} from './utilities';
import React from 'react';
import { Redirect } from 'react-router-dom';

export const ROUTES = {
  // ROOT: { 
  //   path: '/',
  //   exact: false,
  //   component: load('VerifyUserLocation'),
  // },
  HOME: { 
  	path: '/',
  	exact: true,
  	component: load('LandingPage'),
  },
  SHOPS: { 
    path: '/shops',
    exact: true,
    component: load('ShopsListing'),
  },
  SHOPDETAIL: { 
    path: '/shop/:slug',
    exact: true,
    component: load('ShopDetail'),
  },
  CHECKOUT: { 
    path: '/checkout',
    exact: true,
    component: load('Checkout'),
  },
  TRACKORDER: { 
    path: '/track-order/:index',
    exact: true,
    component: load('TrackOrder'),
  },
  USERPROFILE: { 
    path: '/profile',
    exact: true,
    component: load('UserProfile'),
  },
  MYORDERS: { 
    path: '/orders',
    exact: true,
    component: load('MyOrders'),
  },
  SAVEDRESTAURANTS: { 
    path: '/savedrestaurants',
    exact: true,
    component: load('SavedRestaurants'),
  },
  MYFAVOURITES: { 
    path: '/favouriteitems',
    exact: true,
    component: load('FavouriteDishes'),
  },
  MYADDRESSES: { 
    path: '/addresses',
    exact: true,
    component: load('MyAddresses'),
  },
  MYWALLET: { 
    path: '/wallet',
    exact: true,
    component: load('MyWallet'),
  },
  PROMOSHOPS: {
    path: '/promoshops/:index',
    exact: true,
    component: load('PromoShops')
  },
  SHOPCATEGORY: {
    path: '/shopcategory/:index',
    exact: true,
    component: load('CategorizeShop')
  },
  SEARCH: {
    path: '/search',
    exact: true,
    component: load('Search')
  },
  ABOUTUS: {
    path: '/about-us',
    exact: true,
    component: load('pages/AboutUs')
  },
  CAREER: {
    path: '/career',
    exact: true,
    component: load('pages/Career')
  },
  CONTACTUS: {
    path: '/contact-us',
    exact: true,
    component: load('pages/ContactUs')
  },
  COOKIEPOLICY: {
    path: '/cookie-policy',
    exact: true,
    component: load('pages/CookiePolicy')
  },
  HELP: {
    path: '/help',
    exact: true,
    component: load('pages/Help')
  },
  PRIVACYPOLICY: {
    path: '/privacy-policy',
    exact: true,
    component: load('pages/PrivacyPolicy')
  },
  REFUNDCANCELLATION: {
    path: '/refund-cancellation',
    exact: true,
    component: load('pages/RefundCancellation')
  },
  TERMS: {
    path: '/terms',
    exact: true,
    component: load('pages/Terms')
  },
  FAQ: {
    path: '/faq',
    exact: true,
    component: load('Faq')
  },
  PAYMENTSUCCESS: { 
    path: '/paymentsuccess/:index',
    exact: true,
    component: load('PaymentSuccess'),
  },
  PAYMENTFAILURE: { 
    path: '/paymentfailure/:index',
    exact: true,
    component: load('PaymentFailure'),
  },
  LOGOUT: { 
    path: '/logout',
    exact: true,
    component: load('Logout')
  },
  LOGIN: { 
  	path: '/login',
  	exact: true,
    component: load('Login')
  },
  PARTNERWITHUS: { 
    path: '/partner-with-us',
    exact: true,
    component: load('shops/PartnerWithUs')
  },
  RIDERWITHUS: { 
    path: '/ride-with-us',
    exact: true,
    component: load('shops/RideWithUs')
  },
  DELETEUSER: {
    path: '/delete-user',
    exact: true,
    component: load('shops/DeleteUser')
  },
  NOTFOUND: { 
    component: load('NotFound')
  }
};