import {
  UPDATE_GEOLOCATION,
  USER_ADDRESS_LIST,
  ADD_NEW_ADDRESS_SUCCESS,
  DELETE_ADDRESS_SUCCESS,
  COUNTRY_CODE_LIST,
  UPDATE_ADDRESS_SUCCESS,
  _dispatch
} from '../actions/user';

const INITIAL_STATE = {userlocation: {}, useraddress: [], countryCode: []};

export default function(state = INITIAL_STATE, action) {
  switch(action.type) {

    case UPDATE_GEOLOCATION:
      	return { ...state, userlocation: action.payload};
      	break;
    case USER_ADDRESS_LIST:
    	return _dispatch({ ...state, useraddress: action.payload}, true, 'user_address_info');
    	break;
   	case ADD_NEW_ADDRESS_SUCCESS:
   		return  _dispatch({ ...state, useraddress: [...state.useraddress, action.payload]}, true, 'user_address_info');
      break;
    case UPDATE_ADDRESS_SUCCESS:
      return (() => {
        const userAdd = action.payload;
        const itemIndex = action.index;
        const useraddress = [...state.useraddress];
        useraddress.map((item, index) => {
          if(index == itemIndex){
            useraddress[index].address = userAdd.address;
            useraddress[index].houseNumber = userAdd.houseNumber;
            useraddress[index].landMark = userAdd.landMark;
            useraddress[index].type = userAdd.type;
          }
        });
        return _dispatch({ ...state, useraddress}, true, 'user_address_info');
      })();
      break;
    case DELETE_ADDRESS_SUCCESS:
      return (() => {
        const data = [...state.useraddress];
        data.splice(action.payload, 1);
        return _dispatch({ ...state, useraddress: data}, true, 'user_address_info');
      })();
      break;
    case COUNTRY_CODE_LIST:
      return {...state, countryCode: action.payload};
    default:
      return state;
  }
}