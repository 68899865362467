import axios from 'axios';
import { API_URL } from '../constants';
import { _dispatch } from '../utilities';
export { _dispatch };

export const GET_CART_DETAIL = 'GET_CART_DETAIL';
export const MANAGE_USER_CART = 'MANAGE_USER_CART';
export const MANAGE_USER_CHECKOUT_CART = 'MANAGE_USER_CHECKOUT_CART';
export const MANAGE_REPEAT_ITEM_CHECKOUT_CART = 'MANAGE_REPEAT_ITEM_CHECKOUT_CART';
export const ADD_SUGGESTION = 'ADD_SUGGESTION';
export const RIDER_SUGGESTION = 'RIDER_SUGGESTION';
export const ITEM_DETAIL_SUCCESS = 'ITEM_DETAIL_SUCCESS';
export const SET_DELIVERED_ADDRESS = 'SET_DELIVERED_ADDRESS';
export const REMOVE_ITEM_CHECKOUT_CART = 'REMOVE_ITEM_CHECKOUT_CART';
export const FINAL_PAYMENT_SUCCESS = 'FINAL_PAYMENT_SUCCESS';
export const REORDER_SUCCESS = 'REORDER_SUCCESS';
export const SET_MERCHANT_REFERENCE = 'SET_MERCHANT_REFERENCE';

export function getCartDetail(payload){
  	return {
	    type: GET_CART_DETAIL,
	    payload: payload
    }
}

export function manageUserCart(shopId, item){
	return {
	    type: MANAGE_USER_CART,
	    shopId: shopId,
	    item: item
    }
}

export function manageUserCheckoutCart(shopId, item){
	return {
	    type: MANAGE_USER_CHECKOUT_CART,
	    shopId: shopId,
	    item: item
    }
}

export function manageRepeatUserCheckoutCart(shopId, cartItemIndex){
	return {
	    type: MANAGE_REPEAT_ITEM_CHECKOUT_CART,
	    shopId: shopId,
	    cartItemIndex: cartItemIndex
    }
}

export function getCartItemDetail(params){
	return axios({
        method: 'GET',
        params: params,
        url: `${API_URL}/user/getCart`
    });
}

export function getCartItemDetailSuccess(payload){
	return {
      type: ITEM_DETAIL_SUCCESS,
      payload: payload
    }
}

export function addSuggestionForItem(suggestion, index){
	return {
        type: ADD_SUGGESTION,
    	suggestion: suggestion,
    	index: index
    }
}

export function addSuggestionForRider(riderSuggestion){
	return{
		type: RIDER_SUGGESTION,
		riderSuggestion: riderSuggestion
	}
}

export function getCouponList(params){
	return axios({
        method: 'GET',
        params: params,
        url: `${API_URL}/user/getCouponList`
    });
}

export function selectDeliveredAddress(addressId){
	return {
	    type: SET_DELIVERED_ADDRESS,
	    addressId: addressId
    }	
}

export function removeItemCheckoutCart(shopId, index){
	return {
	    type: REMOVE_ITEM_CHECKOUT_CART,
	    shopId: shopId,
	    index: index
    }
}

export function initiateFinalPaymentSuccess(){
	return {
	    type: FINAL_PAYMENT_SUCCESS
    }
}

export function reorder(props){
	return axios({
        method: 'POST',
        data: props,
        url: `${API_URL}/user/reorder`
    });
}

export function reorderSuccess(payload){
	return {
	    type: REORDER_SUCCESS,
	    payload: payload
    }	
}

export function logoutUserSuccess(){
	return {
	    type: FINAL_PAYMENT_SUCCESS
    }
}

export function setMerchantReference(payload){
	return {
	    type: SET_MERCHANT_REFERENCE,
	    payload: payload
    }	
}