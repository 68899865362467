import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import User from './user';
import Auth from './auth';
import Cart from './cart';
import Order from './order';
import Shop from './shop';

const appReducer = combineReducers({
  form: formReducer,
  User,
  Auth,
  Cart,
  Order,
  Shop
});

const rootReducer = (state, action) => {
  return appReducer(state, action)
}

export default rootReducer;