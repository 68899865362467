import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import {ROUTES} from './routes';
import ScrollToTop from 'react-router-scroll-top';

class Wrapper extends Component {
  
  render() {
    return (
      <ScrollToTop>
        <Switch>
          <Route {...ROUTES.HOME} />
          <Route {...ROUTES.ABOUTUS} />
          <Route {...ROUTES.CAREER} />
          <Route {...ROUTES.CONTACTUS} />
          <Route {...ROUTES.COOKIEPOLICY} />
          <Route {...ROUTES.HELP} />
          <Route {...ROUTES.PRIVACYPOLICY} />
          <Route {...ROUTES.REFUNDCANCELLATION} />
          <Route {...ROUTES.TERMS} />
          <Route {...ROUTES.SHOPS} />
          <Route {...ROUTES.PROMOSHOPS} />
          <Route {...ROUTES.SHOPDETAIL} />
          <Route {...ROUTES.CHECKOUT} />
          <Route {...ROUTES.TRACKORDER} />
          <Route {...ROUTES.USERPROFILE} />
          <Route {...ROUTES.SEARCH} />
          <Route {...ROUTES.SHOPCATEGORY} />
          <Route {...ROUTES.FAQ} />
          <Route {...ROUTES.MYORDERS} />
          <Route {...ROUTES.SAVEDRESTAURANTS} />
          <Route {...ROUTES.MYFAVOURITES} />
          <Route {...ROUTES.MYADDRESSES} />
          <Route {...ROUTES.MYWALLET} />
          <Route {...ROUTES.LOGOUT} />
          <Route {...ROUTES.PAYMENTSUCCESS} />
          <Route {...ROUTES.PAYMENTFAILURE} />
          <Route {...ROUTES.PARTNERWITHUS} />
          <Route {...ROUTES.RIDERWITHUS} />
          <Route {...ROUTES.DELETEUSER} />
        </Switch>
      </ScrollToTop>
    );
  }
}


export default Wrapper;