import axios from 'axios';
import { API_URL } from '../constants';
import {_dispatch} from '../utilities';
export {_dispatch};
export const ORDER_DETAIL = 'ORDER_DETAIL';
export const UPDATE_ORDER_STATUS_SOCKET = 'UPDATE_ORDER_STATUS_SOCKET';
export const AVAILABLE_TIME_SLOTS = 'AVAILABLE_TIME_SLOTS';
export const USER_SET_TIME_SLOT = 'USER_SET_TIME_SLOT';
export const CANCEL_SCHEDULE_TIME = 'CANCEL_SCHEDULE_TIME';
export const REMOVE_INFO_ORDER_SUCCESS = 'REMOVE_INFO_ORDER_SUCCESS';
export const DRIVER_LOCATION_SOCKET = 'DRIVER_LOCATION_SOCKET';

export function getOrderDetail(params) {
    return axios({
        method: 'GET',
        params: params,
        url: `${API_URL}/user/getOrderDetail`
    });
}

export function getOrderDetailSuccess(payload){
	return {
      	type: ORDER_DETAIL,
      	payload: payload
    }
}

export function getUserOrders(params) {
    return axios({
        method: 'GET',
        params: params,
        url: `${API_URL}/user/getOrders`
    });
}

export function updateOrderStatusSocket(payload){
  return {
    type: ORDER_DETAIL,
    payload: payload
  }
}

export function getScheduleTimeSlot(params) {
    return axios({
        method: 'GET',
        params: params,
        url: `${API_URL}/user/getSlots`
    });
}

export function getScheduleTimeSlotSuccess(payload){
    return {
        type: AVAILABLE_TIME_SLOTS,
        payload: payload
    }
}

export function setUserOrderTimeSlot(payload){
    return {
        type: USER_SET_TIME_SLOT,
        payload: payload
    }
}

export function cancelScheduleTime(){
    return {
        type: CANCEL_SCHEDULE_TIME
    }
}

export function removeOrderPaymentSuccess(){
    return {
        type: REMOVE_INFO_ORDER_SUCCESS
    }
}

export function driverLocationSocket(payload){
    return {
        type: DRIVER_LOCATION_SOCKET,
        payload: payload
    }
}

export function updateOrderSuccessStatus(props){
    return axios({
        method: 'POST',
        data: props,
        url: `${API_URL}/user/updateOrderSuccessStatus`
    });
}

